<template>
    <svg 
        :width="`${size}px`"
        :height="`${size}px`"
        viewBox="0 0 500 500"        
    >
        <polygon :style="`fill:${colour};`" points="216.5 327.8 216.5 250 283.9 211.1 283.9 288.9 216.5 327.8"/>
        <polygon :style="`fill:${colour};`" points="216.5 414.9 359.3 332.4 359.3 167.5 433 125 433 375 216.5 500 216.5 414.9"/>
        <polygon :style="`fill:${colour};`" points="216.5 414.9 216.5 500 0 375 0 125 73.7 166 73.7 332.5 216.5 414.9"/>
        <polygon :style="`fill:${colour};`" points="0 125 73.7 83 73.7 166 0 125"/>
        <polygon :style="`fill:${colour};`" points="216.5 327.8 149.1 288.9 149.1 211.1 216.5 250 216.5 327.8"/>
        <polygon :style="`fill:${colour};`" points="216.5 250 149.1 211.1 216.5 172.2 283.9 211.1 216.5 250"/>
        <polygon :style="`fill:${colour};`" points="359.3 167.5 147.6 40.3 216.5 0 433 125 359.3 167.5"/>
        <polygon :style="`fill:${colour};`" points="147.6 124.9 147.6 40.3 219.39 83.43 147.6 124.9"/>
    </svg>
</template>

<script>
export default {
    name: 'BriscLogo',
    props: {
        size: {
            type: [String, Number],
            default: 100
        },
        colour: {
            type: String,
            default: '#FFFFFF'
        }
    }
}
</script>
